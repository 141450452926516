<template>
  <!-- page sub header -->
  <div class="page_sub_header">
    <div class="w1300 layout_container">

      <ul class="page_sub_nav">
        <li
          v-for="item in computedListArray.listArray"
          :key="item.id"
          :class="{ active: item.id === 1
          && $route.path === `/${pageSubHeader.parent}/${pageSubHeader.name}` }"
        >
          <router-link :to="ifQuery(item.url)" class="h4 sub_header_list">
            {{ item.text }}
            <span
              class="quantity active"
              v-if="item.text === '課程訂購清單'"
            >{{ groceryCountUpdate }}</span>
            <span
              class="quantity active"
              v-if="item.text === '我的課程'"
            >{{ courseCount }}</span>
          </router-link>
        </li>
      </ul>

    </div>
  </div>
</template>

<script>
import { myCourses } from '@/lib/http';
import { mapState } from 'vuex';

export default {
  name: 'PageSubHeader',
  props: {
    pageSubHeader: {},
    urlQuery: {},
    hostType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      groceryCount: 0,
      courseCount: 0,
    };
  },
  created() {
    if (this.$cookies.get('token')) {
      myCourses().then((result) => {
        if (result !== {}) {
          this.courseCount = result.total;
        } else {
          this.courseCount = 0;
        }
      });
    } else {
      this.courseCount = 0;
    }
  },
  computed: {
    ...mapState([
      'level',
    ]),
    /**
     * 會員專區重組(待刪除功能)：相關查詢其餘頁籤未開發，除了level-1會員外先隱藏
     */
    computedListArray() {
      if (this.pageSubHeader) {
        const copyListArray = [];

        this.pageSubHeader.listArray.forEach((item) => {
          if (item.text === '積分查詢' && this.level * 1 !== 1) {
            return;
          }
          if (parseInt(this.hostType, 10) === 2 && item.text !== '活動資訊') {
            return;
          }
          copyListArray.push(item);
        });
        return { ...this.pageSubHeader, listArray: copyListArray };
      }
      return {};
    },
    groceryCountUpdate() {
      const countArray = JSON.parse(this.$cookies.get('courseInCart'));
      if (countArray !== null) {
        return countArray.length;
      }
      return this.groceryCount;
    },
  },
  methods: {
    ifQuery(url) {
      if (this.urlQuery) {
        return `${url}?${this.urlQuery.name}=${this.urlQuery.value}`;
      }
      return url;
    },
  },
};
</script>
