<template>
  <div class="account">
    <PageSubHeader :pageSubHeader="pageSubHeader" />

    <section id="points">
      <div class="w1300">

        <div class="points_overview">
          <div class="points_overview_count">
            <div class="points_overview_identity">
              <h3 class="txt-bold">{{ user.memberId }} {{ user.realname }}</h3>
              <p>專科證號 {{ user.certificateNumber }}</p>
              <p>積分效期 <span class="txt-dark_green">
                {{ user.validFrom }} ~ {{ user.validTo }}
              </span></p>
              <p v-if="user.validToExtend" class="txt-red">(已展延至 {{ user.validToExtend }})</p>
            </div>
            <div class="points_overview_total">
              <p class="small points">
                <span class="red_bg">A類</span> <span>{{ credits.fraction_a }}積分</span>
              </p>
              <p class="small points">
                <span class="orange_bg">B類</span> <span>{{ credits.fraction_b }}積分</span>
              </p>
              <!-- <h4 class="txt-bold">總積分 {{ credits.total }}</h4> -->
              <h4 class="txt-bold">總積分 {{ credits.fraction_a + credits.fraction_b }}</h4>
            </div>
            <div class="points_overview_description">
              <p class="txt-bold txt-dark_green">
                尚缺A類
                {{ parseInt(credits.lost_a, 10) > 0 ? credits.lost_a : 0 }}
                分，A或B類
                {{ parseInt(credits.lost_b, 10) > 0 ? credits.lost_b : 0 }}
                分，<br>
                請於 {{ user.validToExtend || user.validTo }} 前修完專科醫師證書展延積分。
              </p>
            </div>
          </div>
          <div class="points_overview_search">
            <div class="points_overview_info">
              <p class="small">
                展延<br>如積分未達{{ pointLimit }}分，請於證書到期日前3周向本會提出 「逾期展延申請」。<br><br>
                換證手續<br>如您的積分已滿{{ pointLimit }}分，請於預定換證日前三個月，繳清換證工本費$2,500。
              </p>
              <p class="small txt-red">※本會積分於每月最後一個星期更新，活動若在每月16號以後舉辦，積分於下個月登錄。</p>
            </div>
            <div class="points_overview_filter cf">
              <form
                id="form_search_points"
                action="account_search_points.php"
                method="post"
                @submit.prevent
              >
                <label for="date_from" class="date_from">
                  <!-- <date-pick
                    v-model="prevDate"
                    :inputAttributes="{readonly: true}"
                    :isDateDisabled="isFutureDate"
                    :selectableYearRange="{from: 2015, to: 2025}"
                  ></date-pick> -->
                  <date-pick
                    v-model="prevDate"
                    :inputAttributes="{readonly: true}"
                    :selectableYearRange="{from: 2015, to: 2025}"
                  ></date-pick>
                </label>
                <div class="wave h3 txt-light_green">~</div>
                <div class="form_group">
                  <label for="date_to" class="date_to">
                    <!-- <date-pick
                      v-model="currentDate"
                      :inputAttributes="{readonly: true}"
                      :isDateDisabled="isFutureDate"
                      :selectableYearRange="{from: 2015, to: 2025}"
                    ></date-pick> -->
                    <date-pick
                      v-model="currentDate"
                      :inputAttributes="{readonly: true}"
                      :selectableYearRange="{from: 2015, to: 2025}"
                    ></date-pick>
                  </label>
                  <button
                    type="button"
                    class="small search_btn"
                    @click="filterData"
                  >查詢</button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="points_category">

          <div class="points_category_a">
            <div class="main_title_box">
              <h3 class="main_title txt-bold">A 類積分查詢</h3>
              <div class="img_box">
                <img src="@/statics/img/index/icon_title2.svg" alt="">
              </div>
            </div>
            <!-- 切換頁籤 -->
            <div class="switch_toggle container">
              <ul>
                <li
                  v-for="(switchItem) in switchListA"
                  :key="switchItem.name"
                  :class="{ active: switchItem.name === tableAname }"
                  @click="switchTableA(switchItem.name)"
                >
                  <h4 class="txt-bold">{{ switchItem.name }}</h4>
                </li>
              </ul>
            </div>

            <!-- table area -->
            <div class="table_box">
              <table class="txt-center" v-show="tableAname === '實體活動'">
                <thead>
                  <tr>
                    <th class="min100"><p>{{ activity.th1 }}</p></th>
                    <th class="td-main txt-left"><p>{{ activity.th2 }}</p></th>
                    <th class="min180"><p>{{ activity.th3 }}</p></th>
                    <th class="min100"><p>{{ activity.th4 }}</p></th>
                    <th class="min100"><p>{{ activity.th5 }}</p></th>
                  </tr>
                </thead>
                <tbody id="table_data">
                  <tr v-for="data in eventListFractionA" :key="data.id">
                    <td :data-title="activity.th1" class="nowrap">
                      <p>{{ activityID(data) }}</p></td>
                    <td :data-title="activity.th2" class="td-main txt-left">
                      <p>{{ activityName(data) }}</p></td>
                    <td :data-title="activity.th3" class="nowrap">
                      <p>{{ activityDate(data) }}</p></td>
                    <td :data-title="activity.th4">
                      <p>{{ activityPoint(data) }}</p></td>
                    <td :data-title="activity.th5" class="td-download">
                      <div
                        class="small download a"
                        v-if="isPrintAvailable(data)"
                        @click="updateAttendInfo(data)"
                      >下載</div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="txt-center" v-show="tableAname === '線上課程'">
                <thead>
                  <tr>
                    <th class="td-main txt-left"><p>{{ online.th1 }}</p></th>
                    <th class="min180"><p>{{ online.th2 }}</p></th>
                    <th class="min100"><p>{{ online.th3 }}</p></th>
                    <th class="min100"><p>{{ online.th4 }}</p></th>
                  </tr>
                </thead>
                <tbody id="table_data">
                  <tr v-for="data in courseListFractionA" :key="data.uuid">
                    <td :data-title="online.th1" class="td-main txt-left">
                      <p>{{ activityName(data) }}</p></td>
                    <td :data-title="online.th2" class="nowrap">
                      <p>{{ activityDate(data) }}</p></td>
                    <td :data-title="online.th3">
                      <p>{{ activityPoint(data) }}</p></td>
                    <td :data-title="online.th4" class="td-download">
                      <div
                        class="small download a"
                        v-if="isPrintAvailable(data)"
                        @click="updateAttendInfo(data)"
                      >下載</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="points_total">
              <p class="points small">
                <span class="txt-bold">
                  實體活動 積分</span> <span class="points_num h4 txt-light_green txt-bold">
                  {{ fractionCount.events_fraction_a }}</span>
              </p>
              <p class="points small">
                <span class="txt-bold">
                  線上課程 積分</span> <span class="points_num h4 txt-light_green txt-bold">
                  {{ fractionCount.courses_fraction_a }}</span>
              </p>
              <p class="points small">
                <span class="red_bg">
                  A類</span> <span class="txt-bold">
                  總積分</span> <span class="points_num h4 txt-light_green txt-bold">
                  {{ pointAccumulate(
                    fractionCount.events_fraction_a,
                    fractionCount.courses_fraction_a
                  )}}
                </span>
              </p>
            </div>
          </div>

          <div class="points_category_b">
            <div class="main_title_box">
              <h3 class="main_title txt-bold">B 類積分查詢</h3>
              <div class="img_box">
                <img src="@/statics/img/index/icon_title2.svg" alt="">
              </div>
            </div>
            <!-- 切換頁籤 -->
            <div class="switch_toggle container">
              <ul>
                <li
                  v-for="(switchItem) in switchListB"
                  :key="switchItem.name"
                  :class="{ active: switchItem.name === tableBname }"
                  @click="switchTableB(switchItem.name)"
                >
                  <h4 class="txt-bold">{{ switchItem.name }}</h4>
                </li>
              </ul>
            </div>

            <!-- table area -->
            <div class="table_box">
              <table class="txt-center" v-show="tableBname === '實體活動'">
                <thead>
                  <tr>
                    <th class="min100"><p>{{ activity.th1 }}</p></th>
                    <th class="td-main txt-left"><p>{{ activity.th2 }}</p></th>
                    <th class="min180"><p>{{ activity.th3 }}</p></th>
                    <th class="min100"><p>{{ activity.th4 }}</p></th>
                    <th class="min100"><p>{{ activity.th5 }}</p></th>
                  </tr>
                </thead>
                <tbody id="table_data">
                  <tr v-for="data in eventListFractionB" :key="data.id">
                    <td :data-title="activity.th1" class="nowrap">
                      <p>{{ activityID(data) }}</p></td>
                    <td :data-title="activity.th1" class="td-main txt-left">
                      <p>{{ activityName(data) }}</p></td>
                    <td :data-title="activity.th1" class="nowrap">
                      <p>{{ activityDate(data) }}</p></td>
                    <td :data-title="activity.th1">
                      <p>{{ activityPoint(data) }}</p></td>
                    <td :data-title="activity.th1" class="td-download">
                      <div
                        class="small download a"
                        v-if="isPrintAvailable(data)"
                        @click="updateAttendInfo(data)"
                      >下載</div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="txt-center" v-show="tableBname === '線上課程'">
                <thead>
                  <tr>
                    <th class="td-main txt-left"><p>{{ online.th1 }}</p></th>
                    <th class="min180"><p>{{ online.th2 }}</p></th>
                    <th class="min100"><p>{{ online.th3 }}</p></th>
                    <th class="min100"><p>{{ online.th4 }}</p></th>
                  </tr>
                </thead>
                <tbody id="table_data">
                  <tr v-for="data in courseListFractionB" :key="data.uuid">
                    <td :data-title="online.th1" class="td-main txt-left">
                      <p>{{ activityName(data) }}</p></td>
                    <td :data-title="online.th2" class="nowrap">
                      <p>{{ activityDate(data) }}</p></td>
                    <td :data-title="online.th3">
                      <p>{{ activityPoint(data) }}</p></td>
                    <td :data-title="online.th4" class="td-download">
                      <div
                        class="small download a"
                        v-if="isPrintAvailable(data)"
                        @click="updateAttendInfo(data)"
                      >下載</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="points_total">
              <p class="points small">
                <span class="txt-bold">
                  實體活動 積分</span> <span class="points_num h4 txt-light_green txt-bold">
                  {{ fractionCount.events_fraction_b }}</span>
              </p>
              <p class="points small">
                <span class="txt-bold">
                  線上課程 積分</span> <span class="points_num h4 txt-light_green txt-bold">
                  {{ fractionCount.courses_fraction_b }}</span>
              </p>
              <p class="points small">
                <span class="orange_bg">
                  B類</span> <span class="txt-bold">
                  總積分</span> <span class="points_num h4 txt-light_green txt-bold">
                  {{ pointAccumulate(
                    fractionCount.events_fraction_b,
                    fractionCount.courses_fraction_b
                  )}}
                </span>
              </p>
            </div>
          </div>

        </div>

      </div>
    </section>
  </div>
</template>

<script>
/** Library */
import _ from 'lodash';
import moment from 'moment';
import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';

/** Components */
import PageSubHeader from '@/components/PageSubHeader.vue';

/** Functions */
import { AccountSearchSubHeader } from '@/lib/const';
import {
  fractionSearch, myFractions, userInfo, downloadAttendence,
} from '@/lib/http';
import { mapState, mapMutations, mapActions } from 'vuex';
// import { defaultDateChosen } from '@/lib/public';

export default {
  name: 'AccountSearchPoints',
  data() {
    return {
      ...AccountSearchSubHeader,
      prevDate: '',
      currentDate: '',
      no: '',
      ma_no: '',
      ma_start_date: '',
      ma_end_date: '',
      pointLimit: 180,
      lowestPointA: 140,
      switchListA: [
        {
          name: '實體活動',
        },
        {
          name: '線上課程',
        },
      ],
      switchListB: [
        {
          name: '實體活動',
        },
        {
          name: '線上課程',
        },
      ],
      tableAname: '實體活動',
      tableBname: '實體活動',
      // tableAname: '線上課程',
      // tableBname: '線上課程',
      activity: {
        th1: '活動編號',
        th2: '活動名稱',
        th3: '日期',
        th4: '積分',
        th5: '上課證明',
      },
      online: {
        th1: '課程名稱',
        th2: '日期',
        th3: '積分',
        th4: '上課證明',
      },
      user: {
        realname: '',
        memberId: '',
        certificateNumber: '',
        validFrom: '108-11-01',
        validTo: '114-10-31',
        pointA: 20,
        pointB: 0,
      },
      /**
       * Axios Response Data
       */
      credits: {},
      proUser: {},
      courseListFractionA: [],
      courseListFractionB: [],
      eventListFractionA: [],
      eventListFractionB: [],
      fractionCount: {},
      userForPDF: {},
      activityDataA: [
        {
          id: 1,
          number: '001',
          name: '大台南婦產科醫師聯誼會學術研討會',
          date: '2019-10-10',
          point: 3,
          download: '',
        },
        {
          id: 2,
          number: '001',
          name: '大台南婦產科醫師聯誼會學術研討會',
          date: '2019-10-10',
          point: 3,
          download: '',
        },
        {
          id: 3,
          number: '001',
          name: '大台南婦產科醫師聯誼會學術研討會',
          date: '2019-10-10',
          point: 3,
          download: '',
        },
      ],
      activityDataB: [
        {
          id: 1,
          number: '001',
          name: '大台南婦產科醫師聯誼會學術研討會',
          date: '2019-10-10',
          point: 3,
          download: '',
        },
        {
          id: 2,
          number: '001',
          name: '大台南婦產科醫師聯誼會學術研討會',
          date: '2019-10-10',
          point: 3,
          download: '',
        },
        {
          id: 3,
          number: '001',
          name: '大台南婦產科醫師聯誼會學術研討會',
          date: '2019-10-10',
          point: 3,
          download: '',
        },
      ],
      onlineDataA: [
        {
          id: 1,
          name: '大台南婦產科醫師聯誼會學術研討會',
          date: '2019-10-10',
          point: 3,
          download: '',
        },
        {
          id: 2,
          name: '大台南婦產科醫師聯誼會學術研討會',
          date: '2019-10-10',
          point: 3,
          download: '',
        },
        {
          id: 3,
          name: '大台南婦產科醫師聯誼會學術研討會',
          date: '2019-10-10',
          point: 3,
          download: '',
        },
      ],
      onlineDataB: [
        {
          id: 1,
          name: '大台南婦產科醫師聯誼會學術研討會',
          date: '2019-10-10',
          point: 3,
          download: '',
        },
        {
          id: 2,
          name: '大台南婦產科醫師聯誼會學術研討會',
          date: '2019-10-10',
          point: 3,
          download: '',
        },
        {
          id: 3,
          name: '大台南婦產科醫師聯誼會學術研討會',
          date: '2019-10-10',
          point: 3,
          download: '',
        },
      ],
    };
  },
  created() {
    /** 預設時間區間 */
    // this.prevDate = defaultDateChosen().start;
    // this.currentDate = defaultDateChosen().end;
    this.prevDate = moment().format('YYYY-01-01');
    this.currentDate = moment().format('YYYY-12-31');

    fractionSearch().then((result) => {
      this.credits = result.data;
      this.proUser = result.user;
    });
    myFractions({
      start_date: this.prevDate,
      end_date: this.currentDate,
    }).then((result) => {
      this.courseListFractionA = _.get(result, 'data.fraction_a.courses', []);
      this.courseListFractionB = _.get(result, 'data.fraction_b.courses', []);
      this.eventListFractionA = _.get(result, 'data.fraction_a.events', []);
      this.eventListFractionB = _.get(result, 'data.fraction_b.events', []);
      this.fractionCount = result.count;
    });
    userInfo().then((result) => {
      this.userForPDF = result;
      this.user.realname = result.realname;
      this.user.memberId = result.no;
      this.user.certificateNumber = result.ma_no;
      this.user.validFrom = result.ma_start_date;
      this.user.validTo = result.ma_end_date;
      if (result.ma_extend) {
        this.user.validToExtend = moment(this.user.validTo).add(365, 'd').format('YYYY-MM-DD');
      }
    });
  },
  methods: {
    ...mapMutations([
      'toggleModalInfo',
      'updateModalInfoContent',
    ]),
    ...mapActions([
      'openModal',
    ]),
    activityID(data) {
      return _.get(data, 'event.no_number', '');
    },
    activityName(data) {
      const str = _.get(data, 'course.name') || _.get(data, 'event.name');
      if (str) {
        return str;
      }
      return '';
    },
    activityDate(data) {
      const date = _.get(data, 'created', '');
      if (date) {
        return moment(date).format('YYYY-MM-DD');
      }
      return '';
    },
    activityPoint(data) {
      return _.get(data, 'num');
    },
    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },
    isPrintAvailable(data) {
      return _.get(data, 'hours_print', null);
    },
    filterData() {
      if (this.prevDate && this.currentDate) {
        if (Date.parse(this.prevDate) <= Date.parse(this.currentDate)) {
          myFractions({
            start_date: this.prevDate,
            end_date: this.currentDate,
          }).then((result) => {
            this.courseListFractionA = _.get(result, 'data.fraction_a.courses', []);
            this.courseListFractionB = _.get(result, 'data.fraction_b.courses', []);
            this.eventListFractionA = _.get(result, 'data.fraction_a.events', []);
            this.eventListFractionB = _.get(result, 'data.fraction_b.events', []);
            this.fractionCount = result.count;
          });
        } else {
          this.openModal({
            message: '時間選擇錯誤',
          });
        }
      }
    },
    switchTableA(name) {
      this.tableAname = name;
    },
    switchTableB(name) {
      this.tableBname = name;
    },
    pointAccumulate(val1 = 0, val2 = 0) {
      return val1 + val2;
    },
    /** 上課證明資料存進cookies */
    updateAttendInfo(data) {
      // const isPrintAvailable = _.get(data, 'hours_print', null);
      // console.log('attend', data);
      if (this.isPrintAvailable(data)) {
        const target = _.get(data, 'hours_uuid', null);
        if (target) {
          downloadAttendence({
            course_hours_uuid: target,
          }).then((result) => {
            if (result.data.status) {
              this.$cookies.set('attendInfo', JSON.stringify(result.data.result.data));
              const routeData = this.$router.resolve({ path: '/attendance' });
              window.open(routeData.href, '_blank');
            } else {
              this.openModal({
                message: result.data.message,
              });
            }
          });
        }
      } else {
        this.openModal({
          message: '未開放列印，如欲列印請洽學會',
        });
      }
    },
  },
  computed: {
    ...mapState([
      'realname',
    ]),
    activityPointA() {
      let totalPoint = 0;
      this.activityDataA.forEach((data) => {
        totalPoint += data.point;
      });
      return totalPoint;
    },
    onlinePointA() {
      let totalPoint = 0;
      this.onlineDataA.forEach((data) => {
        totalPoint += data.point;
      });
      return totalPoint;
    },
    activityPointB() {
      let totalPoint = 0;
      this.activityDataB.forEach((data) => {
        totalPoint += data.point;
      });
      return totalPoint;
    },
    onlinePointB() {
      let totalPoint = 0;
      this.onlineDataB.forEach((data) => {
        totalPoint += data.point;
      });
      return totalPoint;
    },
    totalPointB() {
      return this.activityPointB + this.onlinePointB;
    },
    totalPoint() {
      return this.totalPointA + this.totalPointB;
    },
    verifyPointA() {
      const offsetPointA = this.lowestPointA - this.totalPointA;
      switch (true) {
        case offsetPointA < 0:
          return 0;
        default:
          return offsetPointA;
      }
    },
    verifyMixedPoint() {
      const maxMixedPoint = this.pointLimit - this.lowestPointA;
      if (this.totalPointA <= this.lowestPointA) {
        if (this.totalPointB <= maxMixedPoint) {
          return maxMixedPoint - this.totalPointB;
        }
        return 0;
      }
      const overflowPointA = this.totalPointA - this.lowestPointA;
      if (maxMixedPoint - overflowPointA - this.totalPointB < 0) {
        return 0;
      }
      return maxMixedPoint - overflowPointA - this.totalPointB;
    },
  },
  components: {
    PageSubHeader,
    DatePick,
  },
};
</script>
